import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useClientPrincipal } from "@aaronpowell/react-static-web-apps-auth";
import trashcan from './trashcan.png';

const DeleteButton = ({ index, requiredRole, onClick }) => {
    const { clientPrincipal, loaded } = useClientPrincipal();

    return (
        <Button
            hidden={!loaded || !clientPrincipal?.userRoles?.some(role => role === requiredRole)}
            id={`delete-button-${requiredRole}${index}`}
            data-testid={`delete-button-${requiredRole}${index}`}
            variant="danger"
            onClick={ async () => onClick()}>
                <img width='32px' height='32px' src={trashcan} />
        </Button>
    )
};

export default DeleteButton;

DeleteButton.propTypes = {
    index: PropTypes.number.isRequired,
    requiredRole: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}
