import './App.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { Row } from 'react-bootstrap';
import AuthorizedRoute from './components/AuthorizedRoute';
import Header from './components/Header';
import Footer from './components/Footer';
import ReleaseGroupsTabs from './components/ReleaseGroupsTabs';
import ReleaseGroupForm from './components/ReleaseGroupForm';
import ProductReleaseForm from './components/ProductReleaseForm';
import ReleaseCompatibilityForm from './components/ReleaseCompatibilityForm';
import FilterCompatibleReleases from './components/FilterCompatibleReleases';
import FeedbackFormModal from './components/FeedbackFormModal';
import { ClientPrincipalContextProvider } from "@aaronpowell/react-static-web-apps-auth";


function App() {
  const [data, setData] = useState();
  const [categories, setCategories] = useState([]);
  const [releases, setReleases] = useState();

  useEffect(() => {
    var uniqueCategories = []; 
      (async function () {
        try {
          const releaseGroups = await( await fetch(`/api/releaseGroups`)).json();
          setData(releaseGroups);
          
          uniqueCategories = await( await fetch(`/api/categories`)).json();
          setCategories(uniqueCategories);
        } catch(error) {
          console.log(error);
        }
      })();
      var tempReleases = {};
      (async function () {
        try {
          const products = await(await fetch(`api/products`)).json();
          await products.map(product => (
              (async function () {
                  const releasesRes = await(await fetch(`api/${product.id}/releases`)).json();
                  tempReleases[product.name] = releasesRes;
                  }
              )()
          ))
          setReleases(tempReleases);
        } catch(error) {
          console.log(error);
        }
      }())
    },[]);

    const updateReleaseGroups = async () => {
      try {
        const releaseGroups = await( await fetch(`/api/releaseGroups`)).json();
        setData(releaseGroups);
      } catch(error) {
        console.log(error);
      }
    }

  return (
    <ClientPrincipalContextProvider>
      <Container>
        <Row>
          <Header />
        </Row>
        <Row>
          <BrowserRouter>
            <Switch>
              <AuthorizedRoute path="/ReleaseGroupForm" requiredRole="createReleaseGroup" alternateChildren={<ReleaseGroupsTabs
                data={ data }
                categories={ categories }
              />}>
                <ReleaseGroupForm releases={releases} />
              </AuthorizedRoute>
              <AuthorizedRoute path="/ProductRelease" requiredRole="createRelease" alternateChildren={<ReleaseGroupsTabs
                data={ data }
                categories={ categories }
              />}>
                <ProductReleaseForm />
              </AuthorizedRoute>
              <AuthorizedRoute path="/AddRemoveReleaseLinks" requiredRole="createLinks" alternateChildren={<ReleaseGroupsTabs
                data={ data }
                categories={ categories }
              />}>
                <ReleaseCompatibilityForm />
              </AuthorizedRoute>
              <Route path="/FilterCompatibleReleases" >
                <FilterCompatibleReleases />
              </Route>
              <Route path="/">
                <ReleaseGroupsTabs  
                  data={ data }
                  categories={ categories }
                  updateReleaseGroups={updateReleaseGroups}
                />
              </Route>
            </Switch>
          </BrowserRouter>
          <FeedbackFormModal />
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </ClientPrincipalContextProvider>
  );
}

export default App;
