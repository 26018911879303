import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Footer = () => {

  return (
    <footer style={{
                background: 'black',
                color: 'white',
                marginTop: '25px'}}>
        <Row style={{background: '#ed1b2e', color: 'white'}}>
            <br />
            <br />
        </Row>
        <Row style={{background: 'black', color: 'white', marginTop: '10px'}}>
            <Col style={{textAlign: 'center'}}>
                <a href="https://www.hypertherm.com/en-US/" target="_blank" rel="noreferrer"
                    style={{color: 'white', textDecoration: 'none'}}>
                    Hypertherm.com
                </a> &nbsp; | &nbsp; 
                <a href="https://www.hypertherm.com/en-US/contact-us/" target="_blank" rel="noreferrer"
                    style={{color: 'white', textDecoration: 'none'}}>
                    Contact us
                </a> &nbsp; | &nbsp; 
                <a href="https://www.hypertherm.com/en-US/policies/privacy/" target="_blank" rel="noreferrer"
                    style={{color: 'white', textDecoration: 'none'}}>
                    Privacy policy
                </a> &nbsp; | &nbsp; 
                <a href="https://www.hypertherm.com/en-US/policies/terms-of-use/" target="_blank" rel="noreferrer"
                    style={{color: 'white', textDecoration: 'none'}}>
                    Terms of use
                </a> &nbsp; | &nbsp; 
                <a href="https://www.hypertherm.com/en-US/policies/gdpr/" target="_blank" rel="noreferrer"
                    style={{color: 'white', textDecoration: 'none'}}>
                    GDPR statement
                </a>
            </Col>
        </Row>
        <Row style={{background: 'black', color: 'white', marginTop: '15px'}}>
            <Col style={{textAlign: 'center'}}>
                <h4>CONNECT WITH US</h4>
            </Col>
        </Row>
        <Row style={{background: 'black', color: 'white'}}>
            <Col style={{textAlign: 'center'}}>
                <a href="https://www.instagram.com/hypertherminc/" target="_blank" rel="noreferrer">
                    <img src="https://www.hypertherm.com/globalassets/home/footer-icons/fa-instagram_256_ffffff.png?width=36&amp;quality=75&amp;upscale=false&amp;mode=crop"
                        alt="Instagram" title="Instagram" style={{height: '28px', marginLeft: '15px', marginTop: '10px' , marginBottom: '10px'}} />
                </a>
                <a href="https://www.pinterest.com/Hypertherm/" target="_blank" rel="noreferrer">
                    <img src="https://www.hypertherm.com/globalassets/home/footer-icons/icon-pin.png?width=36&amp;quality=75&amp;upscale=false&amp;mode=crop"
                        alt="Pinterest" title="Pinterest" style={{height: '28px', marginLeft: '15px', marginTop: '10px' , marginBottom: '10px'}} />
                </a>
                <a href="https://www.linkedin.com/company/38885" target="_blank" rel="noreferrer">
                    <img src="https://www.hypertherm.com/globalassets/home/footer-icons/icon-linkedin.png?width=36&amp;quality=75&amp;upscale=false&amp;mode=crop"
                        alt="LinkedIn" title="LinkedIn" style={{height: '28px', marginLeft: '15px', marginTop: '10px' , marginBottom: '10px'}} />
                </a>
                <a href="https://www.youtube.com/user/HyperthermInc" target="_blank" rel="noreferrer">
                    <img src="https://www.hypertherm.com/globalassets/home/footer-icons/youtube_social_square_white.png?width=36&amp;quality=75&amp;upscale=false&amp;mode=crop"
                        alt="YouTube" title="YouTube" style={{height: '28px', marginLeft: '15px', marginTop: '10px' , marginBottom: '10px'}} />
                </a>
                <a href="https://twitter.com/hypertherm" target="_blank" rel="noreferrer">
                    <img src="https://www.hypertherm.com/globalassets/home/footer-icons/icon-tw.png?width=36&amp;quality=75&amp;upscale=false&amp;mode=crop"
                        alt="Twitter" title="Twitter" style={{height: '28px', marginLeft: '15px', marginTop: '10px' , marginBottom: '10px'}} />
                </a>
                <a href="https://www.facebook.com/Hypertherm" target="_blank" rel="noreferrer">
                    <img src="https://www.hypertherm.com/globalassets/home/footer-icons/icon-fb.png?width=36&amp;quality=75&amp;upscale=false&amp;mode=crop"
                        alt="Facebook" title="Facebook" style={{height: '28px', marginLeft: '15px', marginTop: '10px' , marginBottom: '10px'}} />
                </a>
            </Col>
        </Row>
        <div style={{textAlign: 'right'}}>© 2021 Hypertherm, Inc.</div>
    </footer>
  )
};

export default Footer;