import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReleaseGroupRows from './ReleaseGroupRows';
import { useClientPrincipal } from "@aaronpowell/react-static-web-apps-auth";

const ReleaseGroupsHeader = ({ data, selectedCategory, swap, updateReleaseGroups }) => {
    const { clientPrincipal } = useClientPrincipal();
    const [headers, setHeaders] = useState([]);
    const [products, setProducts] = useState([]);
    const [releaseDates, setReleaseDates] = useState([]);
    var tempHeaders = [];

    useEffect(async () => {
        tempHeaders = [];
        tempHeaders = [...selectedCategory.products];
        tempHeaders.sort();
        tempHeaders.unshift("Date");

        try{
            let productList = await(await fetch(`api/products`)).json();
            productList.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
            setProducts(productList);
        } catch(error) {
            console.log(error);
        }

        if (!swap && clientPrincipal?.userRoles?.some(role => role === "deleteReleaseGroup")) {
            tempHeaders.unshift("Delete");
        }
        setHeaders(tempHeaders);
        
        var tempDates = [];
	
        if(data) {
            data.releaseGroups.filter(releaseGroup => releaseGroup.category === selectedCategory.name).map(release => (
            tempDates.push(release.date)
            ))
            tempDates.unshift("Products");
            setReleaseDates(tempDates);
        }
    },[]);
    return (
        <>
            <thead>
                <tr>
                    {
                        swap ?
                            releaseDates.map((date, index) => (
                                <th key={date + index}>
                                    {date}
                                </th>
                            )) :
                            headers.map((headerName, index) => (
                                <th key={headerName + index}>
                                    {headerName}
                                </th>
                            ))
                    }
                </tr>
            </thead>
            <ReleaseGroupRows
                products={products}
                headers={headers}
                releaseGroups={data.releaseGroups.filter(releaseGroup => releaseGroup.category === selectedCategory.name)}
                swap={swap}
                updateReleaseGroups={updateReleaseGroups}
            />
        </>
    );
}
export default ReleaseGroupsHeader;

ReleaseGroupsHeader.propTypes = {
    data: PropTypes.objectOf(PropTypes.array, PropTypes.object).isRequired,
    selectedCategory: PropTypes.object.isRequired,
    releaseDates: PropTypes.arrayOf(PropTypes.string),
    swap: PropTypes.bool,
    updateReleaseGroups: PropTypes.func
}
