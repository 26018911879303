import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Container, Alert } from 'react-bootstrap';
import ProductComponentInput from './ProductComponentInput.js';

const ProductReleaseForm = () => {
    const [products, setProducts] = useState([]);
    const [release, setRelease] = useState({product: "", version: "", date: "", components: [] });
    const [validated, setValidated] = useState(false);
    const [hideAlert, setHideAlert] = useState(true);
    const [submitMessage, setSubmitMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");

    useEffect( () => {
        let isMounted = true;
        (async function () {
            try {
                let productList = await (await fetch('/api/products')).json();
                productList.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
                return(productList);
            } catch(error) {
                console.log(error);
            }             
        }())
        .then(productList => {
            if (isMounted) setProducts(productList);
        })
        return () => { isMounted = false };
    }, []);

    const resetComponents = (selectedProduct) => {
        release.components = [];
        products?.find(product => product?.name === selectedProduct)?.components?.map((componentName, index) => {
            release.components[index] = {name: componentName, version: ""}
        });
        
        onChangeRelease({ product: selectedProduct, components: release.components });
    }

    const onChangeComponents = (index, component) => {
        release.components[index] = component;
        onChangeRelease({ components: release.components });
    }

    const onChangeRelease = changes => {
        setHideAlert(true);
        setRelease({ ...release, ...changes });
    }

    const productComponents = () => {
        let selectedProduct = products?.find(product => product?.name === release?.product);
        return selectedProduct?.components;
    }

    async function handleSubmit (event) {
        setHideAlert(true);
        setValidated(true);
        event.preventDefault();

        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            try {
                const response = await fetch(`api/release`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body:
                      JSON.stringify(release),
                    });
                if (response.ok) {
                    document.getElementById("formVersion").value = "";
                    setRelease({ ...release, ...{version: ''} });
                    setSubmitMessage("Product Release Added");
                    setAlertVariant("success");
                    setHideAlert(false);
                } else {
                    if(response.headers.get("Content-Type") === "application/json") {
                        const data = await response.json();
                        setSubmitMessage("Error: " + data.message);
                    } else {
                        setSubmitMessage("Error: " + response.status + " " + response.statusText);
                    }
                    setAlertVariant("danger");
                    setHideAlert(false);
                }
            } catch(error) {
                console.log(error);
            } 
        }
        setValidated(true);
    }

    return(
        <>
        <Container hidden={!products}>
            <h2>New Product Release</h2>
            <Form noValidate id="productReleaseForm" data-testid="productReleaseForm" validated={validated} onSubmit={handleSubmit}>
                <Row style={{padding:'10px',
                            border:'1px solid #000000',
                            borderRadius: '5px'}}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Release Date</Form.Label>
                                <Form.Control
                                    required
                                    id="formDate"
                                    data-testid="formDate"
                                    type="date"
                                    onChange={e => onChangeRelease({ date: e.target.value })}
                                    name="date"
                                />
                                <Form.Control.Feedback id="dateValid" data-testid="dateValid" type="invalid">
                                    Please select a release date.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Product</Form.Label>
                                <Form.Select
                                    required
                                    id="formProduct"
                                    data-testid="formProduct"
                                    aria-label="Product selection"
                                    onChange={e => { resetComponents(e.target.value); }}
                                    name="formProduct">
                                    <option value="">Select a product</option>
                                    {
                                        products.map(p => ( <option value={p.name} key={p.name}>{p.name}</option> ))
                                    }
                                </Form.Select>
                                <Form.Control.Feedback id="productValid" data-testid="productValid" type="invalid">
                                    Please select a product.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Version
                                </Form.Label>
                                <Form.Control
                                    required
                                    id="formVersion"
                                    data-testid="formVersion"
                                    type="text"
                                    placeholder="Enter version"
                                    onChange={e => onChangeRelease({ version: e.target.value })}
                                    name="formVersion"/>
                                <Form.Control.Feedback id="versionValid" data-testid="versionValid" type="invalid">
                                    Please provide a product version.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    {
                        productComponents() ? <Row>
                            <Col>&nbsp;</Col>
                            <Col style={{backgroundColor: '#dddddd',
                                        width: '35%',
                                        padding: '25px',
                                        border: '1px solid #000000',
                                        borderRadius: '5px',
                                        marginLeft: '15px'}}>
                                <Row>
                                    <h5>Product Components</h5>
                                    <hr />
                                </Row>
                                {
                                    productComponents()?.map((componentName, index) => { return (
                                        <>
                                            <ProductComponentInput key={index} index={index} componentName={componentName} onChange={onChangeComponents}/>
                                        </>
                                    )})
                                }
                            </Col>
                        </Row> : <></>
                    }
                </Row>
                <Button id="submit" data-testid="submit" variant="primary" type="submit"
                        style={{ fontWeight: 'bold', marginLeft: '25px', marginTop: '10px' , marginBottom: '10px' }}>
                        Submit
                </Button>
                <br />
                <br />
                <Alert id="productReleaseAlert" data-testid="productReleaseAlert"
                    hidden={hideAlert} variant={alertVariant}>{submitMessage}</Alert>
            </Form>
        </Container>
        <p hidden={products}>Loading...</p>
        </>
    )
}

export default ProductReleaseForm;
