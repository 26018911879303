import React, { useState, useEffect } from 'react';
import 'react-dropdown/style.css';
import PropTypes from "prop-types";
import { Container, Col, Form, Row, Button, Alert } from 'react-bootstrap';

let formData = {};

const ReleaseGroupForm  = ({releases}) => {
    const [hideAlert, setHideAlert] = useState(true);
    const [submitMessage, setSubmitMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [validated, setValidated] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        (async function () {
            try {
                const categoryList = await(await fetch(`api/categories`)).json();
                setCategories(categoryList);
            } catch(error) {
                console.log(error);
            }
        })();
    }, []);

    const determineProducts = () => {
        let productList = [];
        let category = categories.find(category => category.name === selectedCategory);
        productList = category?.products?.length > 0 ? category?.products : [];
        productList.sort();
        return productList;
    }

    const handleCategoryChanged = (event) => {
        setHideAlert(true);
        determineProducts().map(headerName => ( document.getElementById("versions" + headerName).value = ""));
        formData = {};
        setSelectedCategory(event.target.value);
        formData.category = event.target.value;
    }

    const handleChange = (event, headerName) => {
        setHideAlert(true);
        formData[headerName] = event.target.value;
    }
    const handleDateChange = (event) => {
        setHideAlert(true);
        const name = event.target.name.toLowerCase();
        formData[name] = event.target.value;
    };

    async function handleSubmit(event) {
        setHideAlert(true);
        setValidated(true);
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const response = await fetch(`api/releaseGroup`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body:
                        JSON.stringify(formData),
                });

                if (response.ok) {
                    setSubmitMessage("Release Group Added");
                    setAlertVariant("success");
                    setHideAlert(false);
                } else if(response.headers.get("Content-Type") === "application/json") {
                    const data = await response.json();
                    setSubmitMessage("Error: " + data.message);
                    setAlertVariant("danger");
                    setHideAlert(false);
                } else {
                    const data = await response.json();
                    setSubmitMessage(`Error: ${response.status} ${data.message}`);
                    setAlertVariant("danger");
                    setHideAlert(false);
                }
            } catch(error) {
                let errorMsg = "";
                if(error?.response?.data) {
                    errorMsg = "Error: " + error.response.data;
                } else {
                    errorMsg = "Error: " + error;
                }
                setSubmitMessage(errorMsg);
                setAlertVariant("danger");
                setHideAlert(false);
            }
        }
    }
    return releases ? (
        <Container fluid style={{padding:'25px'}}>
            <h2>Release Group</h2>
            <Form id="releaseGroupForm" data-testid="releaseGroupForm" style={{fontWeight: "bolder"}} noValidate validated={validated} onSubmit={handleSubmit}>
                <Row style={{padding: '20px'}}>
                    <Col key={`RGForm-category`} md="auto">
                        <Form.Group>
                            <Form.Label>Product Category</Form.Label>
                            <Form.Select
                                required
                                id={'categorySelector'}
                                data-testid={'categorySelector'}
                                aria-label={'Category Selection'}
                                onChange={handleCategoryChanged}>
                                <option value="">Select a category</option>
                                {
                                    categories.map(c => ( <option value={c.name} key={c.name}>{c.name}</option> ))
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please select a product category.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{padding:'10px',
                            border:'1px solid #000000',
                            borderRadius: '5px'}}>
                    <Row>
                        <Col style={{width:'160px'}}>
                            <Form.Group  style={{backgroundColor:'#666666', color:'blue', width:'180px'}} className="mb-3" controlId="Date">
                                <Form.Label style={{padding:'5px', backgroundColor:'#666666', color:'white'}}>Release Date</Form.Label>
                                    <Form.Control
                                        type="date" 
                                        placeholder="Select a date" 
                                        onChange={handleDateChange}
                                        name="Date"
                                        data-testid="date"
                                        required
                                    />
                                    <Form.Control.Feedback style={{color:'white'}} type="invalid">
                                        Please choose a date.
                                    </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Col sm="10">
                        <Row>
                            {
                                determineProducts().map((headerName, index) => (
                                    index > 20 ? null :
                                    <Col key={`RGForm-${headerName}`} md="auto" style={{border:'1px solid #ffffff', width:'250px'}}>
                                        <Form.Group  style={{backgroundColor:'#666666', color:'blue'}} className="mb-3">
                                            <Form.Label style={{padding:'5px', backgroundColor:'#666666', color:'white'}}>{ headerName }</Form.Label>
                                                <Form.Select
                                                    required
                                                    id={`versions${headerName}`}
                                                    data-testid={headerName}
                                                    aria-label="version"
                                                    onChange={e => handleChange(e, headerName)}
                                                    key={`versions${headerName}`}
                                                    >
                                                    <option value="">Select a version</option>
                                                    {
                                                        releases[headerName].map(
                                                            p => (<option 
                                                                value={p.version} 
                                                                name={p.Product} 
                                                                key={p.version}
                                                                >
                                                                    {p.version}
                                                                </option>)    
                                                        )
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback style={{color:'white'}} type="invalid">
                                                    Please choose a version.
                                                </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
                <Button variant="primary" type="submit" data-testid='submit'
                    style={{ fontWeight: 'bold', marginLeft: '25px', marginTop: '10px' , marginBottom: '10px' }}>
                    Submit
                </Button>
                <br />
                <br />
                <Alert hidden={hideAlert} variant={alertVariant}>{submitMessage}</Alert>
            </Form>
        </Container>
    ) : (<div>Loading...</div>)
}

export default ReleaseGroupForm;

ReleaseGroupForm.propTypes = {
    releases: PropTypes.objectOf(PropTypes.array, PropTypes.object),
}
