import React from 'react';
import { Route } from "react-router";
import PropTypes from "prop-types";
import { useClientPrincipal } from "@aaronpowell/react-static-web-apps-auth";

const AuthorizedRoute = ({ children, requiredRole, path, alternateChildren }) => {
    const { clientPrincipal, loaded } = useClientPrincipal();
    var retElements = alternateChildren;
    var retPath = "/"
    if(loaded && clientPrincipal?.userRoles?.some(role => role === requiredRole))
    {
        retElements = children;
        retPath = path;
    }
    return <Route path={retPath}>{retElements}</Route>;
}

export default AuthorizedRoute;

AuthorizedRoute.propTypes = {
    children: PropTypes.node.isRequired,
    requiredRole: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    alternateChildren: PropTypes.node.isRequired
}