import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Form, Button, Container } from 'react-bootstrap';
import ProductVersionSelector from './ProductVersionSelector';
import ProductComponentModal from './ProductComponentModal';

const FilterCompatibleReleases = () => {
    const [products, setProducts] = useState(null);
    const [features, setFeatures] = useState(null);
    const [numRows, setNumRows] = useState(1);

    const [releases, setReleases] = useState([]);
    const notCompatibleMessage = "No comptatible releases found.";
    const [filterByProduct, setFilterByProduct] = useState('');
    const [filterByFeature, setFilterByFeature] = useState('');
    
    let mounted = false;

    useEffect(() => {
        mounted = true;
        (async function () {
            try{
                let productList = await(await fetch(`api/products`)).json();
                productList.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
                let featuresList = await(await fetch(`api/categories/features`)).json();
                featuresList.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
                if (mounted) {
                    setProducts(productList);
                    setFeatures(featuresList);
                }
            } catch(error) {
                console.log(error);
            }
        })();
        return () => mounted = false; // cleanup function
    }, []);

    const releaseChanged = async (i, selectedRelease) => {
        try {
            if(selectedRelease.product != '' && selectedRelease.version != '') {
                let getFullRelease = await(await fetch(`api/${selectedRelease.product}/${selectedRelease.version}/release`)).json();
                releases[i] = getFullRelease;
                setReleases([...releases]);
            } else {
                releases[i] = {};
                setReleases([...releases]);
            }
        } catch(error) {
            console.log(error);
        }
    }

    const determineProducts = () => {
        let productsList = products;
        if(filterByFeature !== '') {
            let selectedFeature = features?.find(feature => feature.name === filterByFeature);
            productsList = products.filter(obj => selectedFeature?.products?.some(product => product === obj.name))
        }

        return productsList;
    }

    const determineCompatibility = () => {
        let displayList = [];
        
        // remove non-selected release items
        let selectedReleases = releases.filter(release => release?.id);

        // make sure at least one release has been selected
        if(selectedReleases?.length > 0){
            // try to get the 1st release's compatibility array
            let baseCompReleases = [];
            if(selectedReleases[0]?.compatibleReleases?.length > 0)
            {
                baseCompReleases = [...selectedReleases[0]?.compatibleReleases];
            }

            // does 1st release have any compatibile releases
            if(baseCompReleases?.length > 0) {
                // initialize displayList so we can edit it safely
                displayList = [...baseCompReleases];

                // only need to adjust displayList if more than 1 selected release
                if(selectedReleases?.length > 1) {

                    // for each selected release compare compatibility arrays with displayList
                    selectedReleases.map((releaseA) => {

                        // if any release has an empty compatibility array then there are no compatible releases
                        if(releaseA?.compatibleReleases?.length > 0) {

                            // remove non compatible releases from displayList when they don't match
                            baseCompReleases.map((releaseB, k) => {
                                if(!releaseA.compatibleReleases.some(x => x?.id == releaseB?.id))
                                {
                                    displayList[k] = {};
                                }
                            })
                            displayList = displayList.filter(release => release?.id);
                            baseCompReleases = [...displayList];
                        } else {
                            displayList = [];
                        }
                    })
                }
            }
        }
        
        if(filterByProduct != '')
        {
            displayList = displayList.filter(obj => obj.product == filterByProduct);
        }
        
        if(displayList.length <= 0) {
            displayList = [{id: -1, product: notCompatibleMessage, version: ''}];
        }

        return displayList;
    }

    const buildTableItem = (release, index) => {
        let product = {};
        product = products?.find(product => product.name === release.product);

        return product?.components ? 
            <>
                <tr data-testid={`compatibleRelease${index}`}>
                    <td>{release?.product}</td>
                    <td><ProductComponentModal products={products} release={release} /></td>
                </tr>
            </> :
            <>
                <tr data-testid={`compatibleRelease${index}`}>
                    <td>{release?.product}</td>
                    <td>{release?.version}</td>
                </tr>
            </>;
    }

    return products ? (
        <>
            <Container>
                <h2>Compatibility Filter</h2>
                <Form data-testid="form">
                    <Row>
                        <Row>
                            <Col>
                                <Button
                                    id="add-release-button"
                                    variant="secondary"
                                    style={{ fontWeight: 'bold', marginLeft: '10px', marginTop: '10px' , marginBottom: '10px' }}
                                    onClick={() => {
                                        if(numRows !== products.length)
                                        {
                                            setNumRows(numRows + 1);
                                        }
                                    }}>
                                    (+) Add Release
                                </Button>
                                <Button
                                    id="remove-release-button"
                                    variant="secondary"
                                    style={{ fontWeight: 'bold', marginLeft: '10px', marginTop: '10px' , marginBottom: '10px' }}
                                    onClick={() => { 
                                        if(numRows > 1)
                                        {
                                            releases.splice(numRows-1, 1);
                                            setNumRows(numRows - 1);
                                            setReleases(releases);
                                        }
                                    }}>
                                    (-) Remove Release
                                </Button>
                            </Col>
                        </Row>
                        <Col style={{width: '50%',
                                    padding:'10px',
                                    border:'1px solid #000000',
                                    borderRadius: '5px'}}>
                            <Form.Group className="mb-3">
                                <Form.Label>Filter by Feature</Form.Label>
                                <Form.Select
                                    aria-label={`Feature Selection Filter`}
                                    onChange={(event) => {
                                        setFilterByFeature(event.target.value);
                                    }}
                                    data-testid={`featuresDropdownFilter`}>
                                    <option value="" key="default">Select a feature</option>
                                    {
                                        features?.map(feature => ( <option value={feature.name} key={feature.name}>{feature.name}</option> ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        {
                            [...Array(numRows)].map((_, index) => { return (
                                <ProductVersionSelector key={index} index={index} products={determineProducts()} onChange={releaseChanged}/>
                            )})
                        }
                        </Col>
                        <Col style={{backgroundColor: '#cccccc',
                                    width: '35%',
                                    padding: '25px',
                                    border: '1px solid #000000',
                                    borderRadius: '5px',
                                    marginLeft: '15px'}}>
                            <Form.Group className="mb-3">
                                <Form.Label>Filter by Product</Form.Label>
                                <Form.Select
                                    aria-label={`Product Selection Filter`}
                                    onChange={(event) => { 
                                        setFilterByProduct(event.target.value);
                                    }}
                                    data-testid={`productsDropdownFilter`}>
                                    <option value="" key="default">Select a product</option>
                                    {
                                        products.map(product => ( <option value={product.name} key={product.name}>{product.name}</option> ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Table striped bordered style={{backgroundColor: '#ffffff'}}>
                                <thead>
                                    <tr>
                                        <th>
                                            Release Info
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    determineCompatibility()?.map((release, index) => { return (
                                        buildTableItem(release, index)
                                    )})
                                }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    ) : <p>Loading...</p>;
}

export default FilterCompatibleReleases;
