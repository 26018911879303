import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Container, Modal, Alert } from 'react-bootstrap';
import { useClientPrincipal } from "@aaronpowell/react-static-web-apps-auth";
import ProductVersionSelector from './ProductVersionSelector';

const ReleaseCompatibilityForm = () => {
    const { clientPrincipal, loaded } = useClientPrincipal();
    const [hideAlert, setHideAlert] = useState(true);
    const [submitMessage, setSubmitMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [validated, setValidated] = useState(false);
    const [products, setProducts] = useState(null);
    const [numRows, setNumRows] = useState(2);
    const [releases, setReleases] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    let submitType = '';

    useEffect(() => {
        (async function () {
            try {
                let productList = await(await fetch(`api/products`)).json();
                productList.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
                setProducts(productList);
            } catch(error) {
                console.log(error);
            }
        })();
    }, []);

    function releaseChanged (index, selectedRelease) {
        setHideAlert(true);
        releases[index] = selectedRelease;
        setReleases(releases);
    }

    function decideModal(event) {
        setHideAlert(true);
        setValidated(true);
        event.preventDefault();

        const form = document.getElementById("form");

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            if(submitType === 'deleteGroup') {
                setShow(true);
            } else {
                handleSubmit();
            }
        }
        setValidated(true);
    }

    async function handleSubmit (modalDelete) {
        if (releases !== []) {
            let apiMethod = "";
            try {
                if(modalDelete) {
                    apiMethod = "DELETE";
                }
                else {
                    apiMethod = "POST";
                }
                const response = await fetch(`api/compatibility`, {
                    method: apiMethod,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body:
                      JSON.stringify({releases: releases}),
                    });
                if (response.ok) {
                    setSubmitMessage("Compatibility pairing(s) added successfully.");
                    setAlertVariant("success");
                    setHideAlert(false);
                } else if(response.headers.get("Content-Type") === "application/json") {
                    const data = await response.json();
                    setSubmitMessage(data.message);
                    setAlertVariant("danger");
                    setHideAlert(false);
                } else {
                    setSubmitMessage(response.status + " " + response.statusText);
                    setAlertVariant("danger");
                    setHideAlert(false);
                }
            } catch(error) {
                let errorMsg = "";
                if(error?.response?.data) {
                    errorMsg = "Error: " + error.response.data;
                } else {
                    errorMsg = "Error: " + error;
                }
                setSubmitMessage(errorMsg);
                setAlertVariant("danger");
                setHideAlert(false);
            } 
        }
    }

    return products ? (
        <>
            <Container>
                <h2>Compatibility Group</h2>
                <Form noValidate validated={validated} id="form" data-testid="form" onSubmit={decideModal}>
                    <Row>
                        <Row>
                            <Col>
                                <Button
                                    id="add-release-button"
                                    variant="secondary"
                                    style={{ fontWeight: 'bold', marginLeft: '10px', marginTop: '10px' , marginBottom: '10px' }}
                                    onClick={() => {
                                        if(numRows !== products.length)
                                        {
                                            setNumRows(numRows + 1);
                                        }
                                    }}>
                                    (+) Add Release
                                </Button>
                                <Button
                                    id="remove-release-button"
                                    variant="secondary"
                                    style={{ fontWeight: 'bold', marginLeft: '10px', marginTop: '10px' , marginBottom: '10px' }}
                                    onClick={() => { 
                                        if(numRows > 2)
                                        {
                                            releases.splice(numRows-1, 1);
                                            setNumRows(numRows - 1);
                                            setReleases([...releases]);
                                        }
                                    }}>
                                    (-) Remove Release
                                </Button>
                            </Col>
                        </Row>
                        <Col style={{padding:'10px',
                                    border:'1px solid #000000',
                                    borderRadius: '5px'}}>
                        {
                            [...Array(numRows)].map((_, index) => { return (
                                <>
                                    <ProductVersionSelector index={index} products={products} onChange={releaseChanged}/>
                                </>
                            )})
                        }
                        </Col>
                        <Col>
                            <div>
                                &nbsp;
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <Button
                                id="addGroup"
                                data-testid="addGroup"
                                variant="success"
                                type="submit"
                                onClick={() => submitType = 'addGroup'}
                                style={{ fontWeight: 'bold', marginLeft: '25px', marginTop: '10px' , marginBottom: '10px' }}>
                                Add Group
                            </Button>
                            <Button
                                id="deleteGroup"
                                data-testid="deleteGroup"
                                hidden={!loaded || !clientPrincipal?.userRoles?.some(role => role === "deleteLinks")}
                                variant="danger"
                                type="submit"
                                onClick={() => submitType = 'deleteGroup'}
                                style={{ fontWeight: 'bold', marginLeft: '25px', marginTop: '10px' , marginBottom: '10px' }}>
                                Delete Group
                            </Button>
                        </Col>
                        <Col sm="2">
                            <div>
                                &nbsp;
                            </div>
                        </Col>
                    </Row>
                    <Alert hidden={hideAlert} variant={alertVariant}>{submitMessage}</Alert>
                </Form>
                <Modal 
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    data-testid={"deleteModal"}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Release Pairing</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>This action will permenantly remove this compatibility pairing</p>
                        <p>from the database for all users.</p>
                        {
                            releases.map((release, index) => (
                                <p key={"releaseInfo" + index}>&nbsp;&nbsp;&nbsp;&nbsp;{release.product}: {release.version}</p>
                            ))
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button 
                            variant="danger"
                            data-testid="continueBtn"
                            onClick={ async () => {
                                handleSubmit(true);
                                handleClose();
                            }}>Continue</Button>
                        <Button variant="primary" onClick={handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    ) : <p>Loading...</p>;
}

export default ReleaseCompatibilityForm;
