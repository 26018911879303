import React from 'react';
import logo from './Hypertherm-logo.png';
import { Nav, Navbar, Button } from 'react-bootstrap';
import { useClientPrincipal } from '@aaronpowell/react-static-web-apps-auth';

const Header = () => {
  const { clientPrincipal, loaded } = useClientPrincipal();
  
  return (
    <header style={{padding: '0px', marginBottom: '25px'}}>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Navbar.Brand href="/"><img src={logo} alt="Logo" height="80em" /></Navbar.Brand>
        <Nav className="ms-auto align-items-center">
          <Nav.Item>
            <a href="/login">
              <Button
                data-testid="login-button"
                hidden={!loaded || clientPrincipal}
                id="customs-button"
                variant="secondary"
                style={{
                  fontWeight: 'bold',
                  marginLeft: '10px',
                  marginRight: '10px'}}
              >
                Login
              </Button>
            </a>
          </Nav.Item>
          <Nav.Item>
            <a href="/logout">
              <Button
                data-testid="logout-button"
                hidden={!loaded || !clientPrincipal}
                id="customs-button"
                variant="secondary"
                style={{
                  fontWeight: 'bold',
                  marginLeft: '10px',
                  marginRight: '10px'}}
              >
                Welcome {clientPrincipal?.userDetails}, Logout
              </Button>
            </a>
          </Nav.Item>
        </Nav>
      </Navbar>
      <Navbar collapseOnSelect expand="lg" style={{backgroundColor: "#ed1b2e"}} variant="dark">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='me-auto'>
            <Nav.Link
              className='navLink'
              href="/"
              hidden={true}
              data-testid="home-btn">
                Home
            </Nav.Link>
          </Nav>
          <Nav className = 'ms-auto'>
            <Nav.Link
              className='navLink'
              href="/FilterCompatibleReleases"
              hidden={`${window.location.origin}/FilterCompatibleReleases` === window.location.href}
              data-testid="filterCompatibleRelease-btn">
                Compatibility Filter
            </Nav.Link>
            <Nav.Link
              className='navLink'
              href="/AddRemoveReleaseLinks"
              hidden={!loaded || !clientPrincipal?.userRoles?.some(role => role === "createLinks") ||
              `${window.location.origin}/AddRemoveReleaseLinks` === window.location.href}
              data-testid="addRemoveReleaseLinks-btn">
                Edit Compatibility
            </Nav.Link>
            <Nav.Link
              className='navLink'
              href="/ProductRelease"
              hidden={!loaded || !clientPrincipal?.userRoles?.some(role => role === "createRelease") ||
              `${window.location.origin}/ProductRelease` === window.location.href}
              data-testid="productRelease-btn">
                Add Product Release
            </Nav.Link>
            <Nav.Link
              className='navLink'
              href="/"
              hidden={!loaded || `${window.location.origin}/` === window.location.href}
              data-testid="releaseGroups-btn">
                Release Groups
            </Nav.Link>
            <Nav.Link
              className='navLink'
              href="/ReleaseGroupForm"
              hidden={!loaded || !clientPrincipal?.userRoles?.some(role => role === "createReleaseGroup") ||
              `${window.location.origin}/ReleaseGroupForm` === window.location.href}
              data-testid="releaseGroupForm-btn">
                Add Release Groups
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
};

export default Header;
