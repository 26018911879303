import React, { useState } from 'react';
import { Table, Modal, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';

const ProductComponentModal = ({ products, release }) => {
    const [clickedRelease, setClickedRelease] = useState({});
    const [showComponents, setShowComponents] = useState(false);
    const handleCloseComponents = () => setShowComponents(false);
    const handleShowComponents = () => setShowComponents(true);

    const getClickedRelease = async (release) => {
        let fullRelease = {};
        let tempProduct = products.find(product => product.name === release.product);

        if(tempProduct.components)
        {
            try {
                fullRelease = await(await fetch(`api/${release.product}/${release.version}/release`)).json();
            } catch(error) {
                console.log(error);
            }
        }
        setClickedRelease(fullRelease);
    }

    return (
    <>
        <Button
            id={`tableItem-${release?.product}-${release?.version}`}
            data-testid={`tableItem-${release?.product}-${release?.version}`}
            variant="info"
            onClick={async () => {
                getClickedRelease(release);
                handleShowComponents();
            }} >
            {release.version}
        </Button>
        <Modal 
            id={`componentsModal-${clickedRelease?.product}-${clickedRelease?.version}`}
            data-testid={`componentsModal-${clickedRelease?.product}-${clickedRelease?.version}`}
            show={showComponents}
            onHide={handleCloseComponents}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Product Components ({clickedRelease?.product}-{clickedRelease?.version})</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>
                                Component
                            </th>
                            <th>
                                Version
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        clickedRelease?.components?.map(component => { return (
                            <>
                                <tr>
                                    <td>{component?.name}</td>
                                    <td>{component?.version}</td>
                                </tr>
                            </>
                        )})
                    }
                    </tbody>
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    id={`componentsCloseBtn-${clickedRelease?.product}-${clickedRelease?.version}`}
                    data-testid={`componentsCloseBtn-${clickedRelease?.product}-${clickedRelease?.version}`}
                    variant="primary" onClick={handleCloseComponents}>Close</Button>
            </Modal.Footer>
        </Modal>
    </>
)}

export default ProductComponentModal;

ProductComponentModal.propTypes = {
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    release: PropTypes.object.isRequired
}
