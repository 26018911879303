import React, { useState} from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import PropTypes from "prop-types";

const ProductVersionSelector = ({index, products, onChange}) => {
    const [product, setProduct] = useState(null);
    const [versions, setVersions] = useState([]);
    const [versionSelectDisable, setVersionSelectDisable] = useState(true);

    const onChangeProduct = async selectedProduct => {
        setVersionSelectDisable(true);
        if(selectedProduct != '') {
            setProduct(selectedProduct);

            try {
                const versionList = await(await fetch(`api/${selectedProduct}/releases`)).json();
                setVersions(versionList);

                setVersionSelectDisable(false);
            } catch(error) {
                console.log(error);
            }
        }
        else
        {
            setVersions([]);
            onChange(index, {product, version: ''});
        }
    }

    const onChangeVersion = selectedVersion => {
        onChange(index, {product, version: selectedVersion});
    }

    return (
        <>
        <Row>
            <Col>
                <Form.Group className="mb-3" data-testid={`formProduct${index}`}>
                    <Form.Label>Product</Form.Label>
                    <Form.Select
                        required
                        id={`productsDropdown${index}`}
                        data-testid={`productsDropdown${index}`}
                        aria-label={`Product Selection ${index}`}
                        onChange={e => {onChangeProduct(e.target.value)}}>
                        <option value="">Select a product</option>
                        {
                            products.map(p => ( <option value={p.name} key={p.name}>{p.name}</option> ))
                        }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Please select a product.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label>
                        Version
                    </Form.Label>
                    <Form.Select
                        required
                        id={`versionsDropdown${index}`}
                        data-testid={`versionsDropdown${index}`}
                        aria-label={`Version Selection${index}`}
                        onChange={e => {onChangeVersion(e.target.value)}}
                        disabled={versionSelectDisable}>
                        <option value="">Select a version</option>
                        {
                            versions.map(v => ( <option value={v.version} key={v.version}>{v.version}</option> ))
                        }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Please select a version.
                    </Form.Control.Feedback>
                </Form.Group>
            </Col>
        </Row>
        </>
    )
}

export default ProductVersionSelector;

ProductVersionSelector.propTypes = {
    index: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func
}