import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import PropTypes from "prop-types";

const ProductComponentInput = ({index, componentName, onChange}) => {
    const onChangeVersion = version => {
        onChange(index, { name: componentName, version: version });
    }

    return (
        <Row>
            <Form.Label column style={{display: "inline-block", textAlign: "right"}}>
                {componentName}
            </Form.Label>
            <Col>
                <Form.Control
                    id={`componentVersion-${componentName}`}
                    data-testid={`componentVersion-${componentName}`}
                    onChange={e => {onChangeVersion(e.target.value)}}
                    type="text"
                    placeholder="Enter version (optional)"/>
            </Col>
        </Row>
    )
}

export default ProductComponentInput;

ProductComponentInput.propTypes = {
    index: PropTypes.number.isRequired,
    componentName: PropTypes.string.isRequired,
    onChange: PropTypes.func
}