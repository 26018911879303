import React, { useState } from 'react';
import { Tab, Tabs, Row, Col, Container, TabContainer } from 'react-bootstrap';
import PropTypes from "prop-types";
import ReleaseGroupsTable from './ReleaseGroupsTable';
import Toggle from './Toggle';

function ReleaseGroupTabs ({ data, categories, updateReleaseGroups }) {
	const [swap, setSwap] = useState(false);

return (
	<Container>
		<Row >
			<Col>
				<h3>Software Versions</h3>
			</Col>
			<Col>
				<div style={{display: 'flex', justifyContent: 'flex-end'}}>
					<Toggle
						isOn={swap}
						onColor="#2bd96e"
						handleToggle={() => setSwap(!swap)}
					/>
				</div>
			</Col>
		</Row>
		<TabContainer>
			<Tabs transition id="uncontrolled-tab-example">
				{categories.map(category => (
					<Tab 
						eventKey={category.name} 
						title={category.name} 
						key={category.name} 
						data-testid={category.name}>
						<ReleaseGroupsTable
							swap={swap}
							selectedCategory={category}
							data={data}
							updateReleaseGroups={updateReleaseGroups}
						/>
					</Tab>
				))}		
			</Tabs>	
		</TabContainer>
	</Container>
	)
}

export default ReleaseGroupTabs;

ReleaseGroupTabs.propTypes = {
    data: PropTypes.objectOf(PropTypes.array, PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
	updateReleaseGroups: PropTypes.func
}
