import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import ReleaseGroupsHeader from "./ReleaseGroupHeader";

function ReleaseGroupsTable ({ data, selectedCategory, swap, updateReleaseGroups }) {
    return(
        <>
                { data &&
                <Table striped bordered hover responsive>
                    <ReleaseGroupsHeader
                        swap={swap}
                        data={data}
                        selectedCategory={selectedCategory}
                        updateReleaseGroups={updateReleaseGroups}
                    />
                </Table>
                }
        </>
      )
}

export default ReleaseGroupsTable;

ReleaseGroupsTable.propTypes = {
    data: PropTypes.objectOf(PropTypes.array, PropTypes.object).isRequired,
    selectedCategory: PropTypes.object.isRequired,
    swap: PropTypes.bool.isRequired,
    updateReleaseGroups: PropTypes.func
}
