import React from 'react';
import PropTypes from "prop-types";

const Toggle = ({ isOn, handleToggle, onColor }) => {
  return (
    <>
        <label style={{fontWeight: "bold"}}>Swap Rows and Columns</label>
        <input
            checked={isOn}
            onChange={handleToggle}
            className="react-toggle-checkbox"
            id={`react-toggle-new`}
            type="checkbox"
        />
        <label
            style={{background: isOn && onColor}}
            className="react-toggle-label"
            htmlFor={`react-toggle-new`}
        >
            <span className={`react-toggle-button`} />
        </label>
    </>
  );
};

export default Toggle;

Toggle.propTypes = {
    isOn: PropTypes.bool,
    handleToggle: PropTypes.func,
    onColor: PropTypes.string,
}