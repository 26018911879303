import React, { useState } from 'react';
import { Row, Modal, Form, Button, ToggleButton, Alert } from 'react-bootstrap';
import { BsEmojiSmile, BsEmojiNeutral, BsEmojiFrown } from 'react-icons/bs';

const FeedbackFormModal = () => {
    const [modalShow, setModalShow] = useState(false);
    const [modalThankYouShow, setModalThankYouShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [hideAlert, setHideAlert] = useState(true);
    const [submitMessage, setSubmitMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [radioValue, setRadioValue] = useState('smile');
    const [feedback, setFeedback] = useState({rating: 'smile'});

    let tempFeedback = {};

    const ratingButtons = [
        { name: 'Smile', value: 'smile', variant: 'outline-success', icon: <BsEmojiSmile size={40} /> },
        { name: 'Neutral', value: 'neutral', variant: 'outline-warning', icon: <BsEmojiNeutral size={40} />  },
        { name: 'Frown', value: 'frown', variant: 'outline-danger', icon: <BsEmojiFrown size={40} />  },
      ];
  
    const onChangeFeedback = fb => {
        tempFeedback = {
                ...feedback,
                [Object.keys(fb)[0]]: Object.values(fb)[0]
            }

        setFeedback(tempFeedback);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            setSubmitMessage("Feedback cannot be empty.");
            setAlertVariant("danger");
            setHideAlert(false);
            event.stopPropagation();
        } else {
            try {
                const response = await fetch(`api/submitFeedback`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(feedback),
                    });
                    
                if (response.ok) {
                    setModalThankYouShow(true);
                    setSubmitMessage("Thank you for your feedback! :)");
                    setAlertVariant("success");
                    setHideAlert(false);
                } else {
                    if(response.headers.get("Content-Type") === "application/json") {
                        const data = await response.json();
                        setSubmitMessage("Error: " + data.message);
                    } else {
                        setSubmitMessage("Error: " + response.status + " " + response.statusText);
                    }
                    setAlertVariant("danger");
                    setHideAlert(false);
                }
            } catch(error) {
                console.log(error);
            }
            setValidated(true);
        }
    }

    const handleClose = async () => {
        setModalShow(false);
        setModalThankYouShow(false);
        setFeedback({});
        setValidated(false);
        setHideAlert(true);
        setSubmitMessage("");
        setAlertVariant("");
    }

    return (
      <>
        <Row>
            <div style={{display: 'flex', justifyContent: 'flex-end'}} >
                <Button variant="primary" style={{
                    width: '70px',
                    height: '70px',
                    padding: '10px 16px',
                    borderRadius: '35px',
                    textAlign: 'center',
                    alignItems: 'center',
                    lineHeight: '1.33',
                    fontSize: '28px',
                    fontWeight: '800',
                    zIndex: '1',
                    position: 'fixed',
                    right: 25,
                    bottom: 25,
                    }}
                    onClick={() => setModalShow(true)}>?</Button>
            </div>
        </Row>
        <Modal
          id="feedbackModal"
          size="lg"
          show={modalShow}
          onHide={() => handleClose()}
          aria-labelledby="feedbackModal"
          centered
        >
            {modalThankYouShow ?
            <>
                <Modal.Header closeButton>
                    <Modal.Title id="thankYou" aria-labelledby="feedbackModal thankYou">
                        Thank You!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {submitMessage}
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => handleClose()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </>
            :
            <>
                <Modal.Header closeButton>
                    <Modal.Title id="submitFeedback" aria-labelledby="feedbackModal submitFeedback">
                        Submit Feedback
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} data-testid="submitFeedbackForm">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Name: 
                            </Form.Label>
                            <Form.Control
                                data-testid="feedbackName"
                                placeholder="Name (optional)"
                                onChange={e => onChangeFeedback({ name: e.target.value })}
                                name="feedbackName"/>
                            <br />
                            <Form.Label>
                                Company: 
                            </Form.Label>
                            <Form.Control
                                data-testid="feedbackCompany"
                                placeholder="Company (optional)"
                                onChange={e => onChangeFeedback({ company: e.target.value })}
                                name="feedbackCompany"/>
                            <br />
                            {ratingButtons.map((buttonInfo, i) => (
                                <ToggleButton
                                    key={i}
                                    id={`ratingButton-${i}`}
                                    type="radio"
                                    size="xl"
                                    variant={buttonInfo.variant}
                                    value={buttonInfo.value}
                                    checked={radioValue === buttonInfo.value}
                                    onChange={(e) => { setRadioValue(e.currentTarget.value); onChangeFeedback({ rating: e.target.value }) }}
                                >
                                    {buttonInfo.icon}
                                </ToggleButton>
                            ))}
                            <br />
                            <br />
                            <Form.Control
                                required
                                data-testid="feedbackBlock"
                                as="textarea"
                                rows="4"
                                placeholder="Enter feedback"
                                onChange={e => onChangeFeedback({ feedback: e.target.value })}
                                name="feedback"/>
                            <Form.Control.Feedback type="invalid" data-testid="feedbackValid">
                                Please provide some feedbackValid.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Alert hidden={hideAlert} variant={alertVariant}>{submitMessage}</Alert>
                        <Modal.Footer>
                            <Button variant="primary" type="submit" data-testid="submitFeedbackModal"
                                style={{ fontWeight: 'bold', marginLeft: '25px', marginTop: '10px' , marginBottom: '10px' }}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </>}
        </Modal>
      </>
    );
  }

  export default FeedbackFormModal;
