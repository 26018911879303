import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ProductComponentModal from './ProductComponentModal';
import DeleteButton from './DeleteButton';

const ReleaseGroupRows = ({ products, releaseGroups, headers, swap, updateReleaseGroups }) => {
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState("");
    const [selectedReleaseGroup, setSelectedReleaseGroup] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var versions = {};
    var tempVersion = [];

    if(swap) {
        headers = headers.filter(function(item) {
            return item !== "Delete"
        });
        headers = headers.filter(function(item) {
            return item !== "Date"
        });
    }

    const getVersions = () => {
        headers.forEach(element => {
            versions = {...versions, [element]: [] };
        });
        headers.map(header => (
            releaseGroups.map((releaseGroup) => (
                tempVersion = releaseGroup.releases.find(product => product.product === header)?.version,
                tempVersion = tempVersion ? tempVersion : "",
                versions[header].push(tempVersion)
            ))     
        ))
        return versions
    }

    const buildTableItem = (release) => {
        let product = {};
        product = products?.find(product => product.name === release.product);

        return product?.components ? 
            <>
                <ProductComponentModal products={products} release={release} />
            </> :
            <>
                <Form.Label
                    id={`tableItem-${release?.product}-${release?.version}`}
                    data-testid={`tableItem-${release?.product}-${release?.version}`}>
                    {release.version}
                </Form.Label>
            </>;
    }
    
    const deleteReleaseGroup = async (releaseGroup) => {
        {
            try {
                const response = await fetch(`api/releaseGroup`, {
                    method: 'DELETE',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body:
                    JSON.stringify({category: releaseGroup.category, date: releaseGroup.date}),
                    });
                if (response.ok) {
                    setSubmitSuccess(true);
                    setSubmitError(false);
                    updateReleaseGroups();
                } else if(response.headers.get("Content-Type").includes('application/json')) {
                    const data = await response.json();
                    setSubmitError(true);
                    setSubmitErrorMessage(data.message);
                    setSubmitSuccess(false);
                } else {
                    setSubmitError(true);
                    setSubmitErrorMessage(response.status + " " + response.statusText);
                    setSubmitSuccess(false);
                }
            } catch(error) {
                console.log(error);
            }
        }
    }
    return ( !headers ? <></> :
        <tbody>
            {!swap ?
                releaseGroups.map((releaseGroup, index) => (
                    <tr valign='middle' key={"group" + index}>
                        {
                            headers.map(headerName => (
                                <td key={"group" + index + headerName} className="table-cols">
                                    {
                                        headerName === "Delete" ?
                                            <DeleteButton index={index} requiredRole="deleteReleaseGroup" onClick={async () => {
                                                setSelectedReleaseGroup(releaseGroup);
                                                handleShow();
                                            }} /> :
                                            headerName === "Date" ?
                                                releaseGroup.date :
                                                // Check if product and/or product version undefined
                                                releaseGroup.releases.find(
                                                    release => release.product === headerName)?.
                                                    version === undefined ? "" : buildTableItem(
                                                        releaseGroup.releases.find(release => release.product === headerName))
                                    }
                                </td>
                            ))
                        }
                    </tr>
                ))
                :
                headers.map(header => (
                    <tr valign='middle' key={header}>
                        <td>{header}</td>
                        {getVersions()[header].map((versions, i) => (
                            <td key={versions + i}>{versions}</td>
                        ))}

                    </tr>
                ))
            }
            <tr>
                {submitSuccess && <td colSpan='100%'
                                style={{
                                    padding:'10px',
                                    backgroundColor:'green',
                                    color:'white',
                                    borderRadius: '5px'}}>
                                Form successfully submitted
                            </td>}
                {submitError && <td colSpan='100%'
                                style={{
                                    padding:'10px',
                                    backgroundColor:'red',
                                    color:'white',
                                    borderRadius: '5px'}}>
                                Error: {submitErrorMessage}
                            </td>}
            </tr>
            
            <Modal 
                id={"deleteModal"}
                data-testid={"deleteModal"}
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Release Group</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>This action will permenantly remove this release group from the database for all users.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{selectedReleaseGroup.category}: {selectedReleaseGroup.date}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button 
                        variant="danger"
                        data-testid="continueBtn"
                        onClick={ async () => {
                            deleteReleaseGroup(selectedReleaseGroup);
                            handleClose();
                        }}>Continue</Button>
                    <Button variant="primary" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </tbody>
    );
};

export default ReleaseGroupRows;

ReleaseGroupRows.propTypes = {
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    releaseGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    swap: PropTypes.bool,
    updateReleaseGroups: PropTypes.func
}
